import Button from '@components/buttons/button';
import InputNumber from '@components/inputNumber';
import Modal from '@components/modal';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { ShipmentService } from '@services/shipment';
import { leaveEvent, listenChannel } from '@services/socket';
import { ConfigProvider, InputNumber as AntInputNumber } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { voiceMessage } from 'utils/voiceMessage';

import { playAudio } from '../../../../../../../../../utils/playSound';
import styles from './index.module.scss';

const AddModal: FC = () => {
	const {
		operator,
		shipment: {
			emall: { goods },
		},
	} = useStore();

	const [barcode, setBarcode] = useState<number | null>(null);
	const [count, setCount] = useState(1);
	const [bagNumber, setBagNumber] = useState<number | null>(null);
	const [orderNumber, setOrderNumber] = useState<number | null>(null);
	const [error, setError] = useState('');
	const [emptyBarcode, setEmptyBarcode] = useState(false);

	const closeModal = (): void => {
		goods.setSurplusItems(null);
		goods.showModalAdd(false);
		leave();
		setBarcode(null);
		setBagNumber(null);
		setOrderNumber(null);
		setCount(1);
		setError('');
		setEmptyBarcode(false);
	};

	const handleBarcodeChange = (value: number | null): void => {
		setError('');
		setEmptyBarcode(false);
		setBarcode(value);
	};

	const handleBagNumberChange = (value: number | null): void => {
		setError('');
		setBagNumber(value);
	};

	const handleOrderNumberChange = (value: number | null): void => {
		setError('');
		setOrderNumber(value);
	};

	const handleCountChange = (value: number | null): void => {
		if (!value) return;
		setCount(value);
	};

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	const handleSubmitClick = (): void => {
		if (!barcode) return setEmptyBarcode(true);

		ShipmentService.postEmallSurplusItems({
			barcode: String(barcode),
			count,
			...(bagNumber ? { bag_name: String(bagNumber) } : {}),
			order_market_id: orderNumber,
		})
			.then(({ data: items }) => {
				if (items.length) {
					playAudio('warning');
					return goods.setSurplusItems(items);
				}
				playAudio('success');
				goods.loadItems();
				closeModal();
			})
			.catch((err) => {
				playAudio('error');
				setError(err?.message || err?.error || '');
			});
	};

	useEffect(() => {
		if (goods.modalAdd) {
			window.Echo &&
				operator?.id &&
				listenChannel(({ number }) => handleBarcodeChange(+number), '.routing.number', operator?.id);
		}

		return leave;
	}, [goods.modalAdd]);

	useEffect(() => {
		goods.surplusItems?.length === 0 && closeModal();

		goods.surplusItems?.length &&
			voiceMessage({ message: goods.surplusItems[0].store_place_item_text || 'Выберите ячейку', isSave: true });
	}, [goods.surplusItems?.length]);

	return (
		<>
			<Modal
				classNameModal={styles.modal}
				className={styles.modal__body}
				title={null}
				okText="Добавить"
				cancelText="Отмена"
				open={goods.modalAdd}
				onCancel={closeModal}
				onOk={handleSubmitClick}
				hasLines={false}
				centered
				width={400}
				closable={false}
				destroyOnClose
			>
				<ConfigProvider
					theme={{
						token: {
							controlHeight: 38,
							borderRadius: 4,
							colorBgContainerDisabled: '#fff',
							colorTextDisabled: colors.grey1,
						},
					}}
				>
					<h3 className={styles.modal__title}>Добавление товара - излишка</h3>
					<div className={styles.inputs}>
						<InputNumber
							className={styles.input}
							value={barcode}
							setValue={handleBarcodeChange}
							maxLength={50}
							placeholder="Штрихкод"
							required
							errorMessage={emptyBarcode ? 'Обязательное поле' : null}
						/>
						<AntInputNumber
							value={count}
							defaultValue={1}
							min={1}
							max={100}
							formatter={(value) => `${value} шт.`}
							parser={(value) => value?.replace(' шт.', '') as unknown as number}
							onChange={handleCountChange}
						/>
						<InputNumber
							className={styles.input}
							value={bagNumber}
							setValue={handleBagNumberChange}
							placeholder="Номер пакета"
						/>
						<InputNumber
							className={styles.input}
							value={orderNumber}
							setValue={handleOrderNumberChange}
							placeholder="Номер заказа"
						/>
					</div>
				</ConfigProvider>
				{error ? <p className={styles.error}>{error}</p> : null}
			</Modal>
			<Modal
				classNameModal={styles.info}
				className={styles.info__body}
				title={null}
				open={!!goods.surplusItems?.length}
				onCancel={goods.setSurplusItems.bind(null, null)}
				hasLines={false}
				centered
				width={500}
				closable={false}
				footer={null}
			>
				<Icon className={styles.info__icon} imageName="Info" color={colors.grey2} fontSize={72} />
				{goods.surplusItems ? (
					<>
						<h3 className={styles.info__title}>
							Привязка товара заказа №{goods.surplusItems[0]?.item.order_market_id}{' '}
							{goods.surplusItems[0]?.store_place_item_id
								? `к ячейке ${goods.surplusItems[0]?.store_place_item_full_name}`
								: `к новой ячейке`}
						</h3>
						<p className={styles.info__item}>
							{goods.surplusItems[0]?.item.name}
							<br />
							Арт: {goods.surplusItems[0]?.item.item_market_id}
						</p>
						{!goods.surplusItems[0]?.store_place_item_id ? (
							<p className={styles.info__warn}>
								Необходимо отсканировать доступную статичную ячейку. При дальнейшем сканировании товаров данного заказа
								целевая ячейка будет предлагаться автоматически.
							</p>
						) : null}
					</>
				) : (
					'Ошибка'
				)}
				<Button className={styles.info__button} onClick={goods.setSurplusItems.bind(null, null)}>
					Закрыть
				</Button>
			</Modal>
		</>
	);
};

export default observer(AddModal);
