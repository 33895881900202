import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import ModalInfo from '@pages/main/acceptance/components/postal/components/cargo-spaces/components/items-modal/components/confirm-modal';
import { AcceptanceService } from '@services/acceptance';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useMemo, useState } from 'react';

import { playAudio } from '../../../../../../../../../utils/playSound';
import Header from './components/header';
import ItemsTable from './components/table';
import styles from './index.module.scss';

const ItemsModal: FC = () => {
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const {
		notice,
		setLoading,
		acceptance: {
			postal,
			postal: {
				postalCargoSpaces: { modalItems, isOpenModalItems, getModalItemsShared, isSubmitDisable },
			},
		},
	} = useStore();

	const includesStatuses = useMemo(
		() => (statuses: number[]) =>
			statuses.includes(
				postal.postalCargoSpaces.postalCargoSpacesItems?.routing_spaces?.find(
					(el: any) => el.number === modalItems?.number
				)?.status as number
			),
		[modalItems]
	);

	const close = (): void => {
		if (includesStatuses([84])) {
			postal.postalCargoSpaces.setPauseScanned(modalItems?.id as number);
		}
		postal.postalCargoSpaces.setIsOpenModalItems(false);
	};

	const closeAcceptance = async (): Promise<void> => {
		if (modalItems) {
			setLoading(true);
			try {
				await AcceptanceService.postalSpaceClose(modalItems.id);
				await postal.postalCargoSpaces.loadPostalCargoSpaces();
				playAudio('success');
				setIsOpenModal(false);
				postal.postalCargoSpaces.setIsOpenModalItems(false);
			} catch (err: any) {
				playAudio('error');
				notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				setLoading(false);
			}
		} else {
			postal.postalCargoSpaces.setIsOpenModalItems(false);
		}
	};

	return (
		<>
			<Modal
				title={
					<div className={styles.title}>
						<span>{modalItems ? 'Приёмка грузоместа' : 'Приёмка почтовых отправлений'}</span>
						<span>
							{modalItems ? `Мешок ${modalItems?.number}` : 'Отсканируйте пломбу грузоместа или почтовое отправление'}
						</span>
					</div>
				}
				okButtonProps={{
					disabled: isSubmitDisable,
					style: {
						display: includesStatuses([80, 81]) ? 'none' : 'inline',
					},
				}}
				okText="Завершить приемку"
				cancelText="Закрыть"
				onOk={() => {
					if (getModalItemsShared.filter((el) => !el.store_place_item_full_name && !el.is_deleted)?.length) {
						setIsOpenModal(true);
						return;
					}
					closeAcceptance();
				}}
				open={isOpenModalItems}
				width={720}
				onCancel={close}
				className={styles.body}
				classNameModal={styles.modal}
				centered
				destroyOnClose
			>
				<Header />
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					<ItemsTable />
				</OverlayScrollbarsComponent>
			</Modal>
			<ModalInfo />
			<Modal
				onCancel={() => setIsOpenModal(false)}
				classNameModal={styles.modal_confirm}
				width={504}
				destroyOnClose
				centered
				open={isOpenModal}
				footer={false}
				title={false}
			>
				<span className={styles.text}>
					Отсканированы не все посылки из списка.
					<br />
					Досканируйте или примите с расхождением
				</span>
				<div className={styles.buttons}>
					<Button onClick={closeAcceptance}>Принять с расхождением</Button>
					<Button onClick={() => setIsOpenModal(false)} type="primary">
						Досканировать
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default observer(ItemsModal);
