import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import ModalScannedCounter from '@pages/main/components/modal-scanned-counter';
import ModalWarningCollect from '@pages/main/visits/components/visit/compoments/modal-order-collect/components/modal-warning-collect';
import OrderService, { IOrderItemsCollectResponse } from '@services/order';
import { IOrderCollectItem } from '@store/visits/order-collect';
import { InputNumber, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import { playAudio } from '../../../../../../../utils/playSound';
import styles from './index.module.scss';

const ModalOrderCollect: FC = () => {
	const visit = useCurrentVisit();
	const store = useStore();

	const [data, setData] = useState<IOrderItemsCollectResponse | null>(null);
	const [hiddenInputs, setHiddenInputs] = useState<string[]>([]);

	const onClose = async (research?: boolean): Promise<void> => {
		if (research && visit?.orders) {
			await visit?.setStartLoadData(
				{
					id: visit?.orders[0].id,
					type: 'order',
				},
				null
			);
		}
		setData(null);
	};

	const handleDoubleClick = (key: string): void => {
		setHiddenInputs((prev) => [...prev, key]);
	};

	const responseOrderItemScanManual = async (
		data: Pick<IOrderCollectItem, 'product_id' | 'price' | 'store_place_item_id' | 'quantity'> & { orders: number[] }
	): Promise<void> => {
		try {
			store.setLoading(true);
			await OrderService.postOrderScanManual(data);
			await visit?.setOrdersCollect(
				visit?.ordersCollect?.order_ids?.length === 1,
				Number(visit?.ordersCollect?.order_ids[0])
			);
			playAudio('success');
		} catch (err: any) {
			playAudio('error');
			store.notice.setNotice({ message: err?.error || err?.message || '' });
		} finally {
			store.setLoading(false);
		}
	};

	const responseItemsCollect = async (): Promise<void> => {
		try {
			store.setLoading(true);
			const res = await OrderService.postOrderItemsCollect({ order_ids: visit?.ordersCollect?.order_ids || [] });
			if (!res.data.items && visit?.orders) {
				await visit?.setStartLoadData(
					{
						id: visit?.orders[0]?.id,
						type: 'order',
					},
					null
				);
				setHiddenInputs([]);
				visit?.closeModalOrderCollect();
				playAudio('success');
				return;
			}
			setData(res.data);
		} catch (err: any) {
			playAudio('error');
			store.notice.setNotice({ message: err?.error || err?.message || '' });
		} finally {
			store.setLoading(false);
		}
	};

	const columns: ColumnsType<IOrderCollectItem> = [
		{
			dataIndex: 'name',
			title: 'Наименование',
			render: (_, { name }) => <div>{name}</div>,
			width: 453,
		},
		{
			dataIndex: 'cell',
			title: 'Ячейка',
			render: (_, { store_place_full_name }) => <div>{store_place_full_name}</div>,
			width: 117,
		},
		{
			dataIndex: 'count',
			title: 'Кол-во в заказе',
			render: (_, { quantity }) => <div>{quantity}</div>,
			width: 133,
		},
		{
			dataIndex: 'price',
			title: 'Цена',
			render: (_, { price }) => <div>{price}</div>,
			width: 120,
		},
		{
			dataIndex: 'collected_quantity',
			title: 'Кол-во факт',
			render: (_, record) =>
				!hiddenInputs.includes(record.key || '') ? (
					<span>{record.collected_count}</span>
				) : (
					<InputNumber
						onChange={(value) =>
							responseOrderItemScanManual({
								orders: visit?.ordersCollect?.order_ids || [],
								price: record.price,
								quantity: Number(value),
								product_id: record.product_id,
								store_place_item_id: record.store_place_item_id,
							})
						}
						onBlur={() => setHiddenInputs(hiddenInputs.filter((el) => el !== record.key))}
						min={0}
						max={record.quantity}
						defaultValue={record.collected_count}
					/>
				),
			onCell: (record) => ({ onDoubleClick: () => record.key && handleDoubleClick(record.key) }),
			width: 120,
		},
		{
			dataIndex: 'final_price',
			title: 'Сумма',
			render: (_, { final_price }) => <div>{final_price}</div>,
			width: 88,
		},
	];

	return (
		<>
			<ModalScannedCounter
				classModal={styles.modal}
				onClose={() => {
					setHiddenInputs([]);
					visit?.closeModalOrderCollect();
				}}
				totalCounter={visit?.ordersCollect?.count || 0}
				scannedCounter={visit?.ordersCollect?.collected_count || 0}
				description={visit?.ordersCollect?.receiver_fio || ''}
				title={
					<div className={styles.header}>
						<Icon imageName="ShoppingBagBlack" />
						<div>
							{visit?.ordersCollect?.order_ids?.length === 1
								? visit?.orders.find((el) => el.id === visit?.ordersCollect?.order_ids[0])?.market_id
								: 'Все заказы клиента'}
						</div>
					</div>
				}
				isShow={!!visit?.ordersCollect}
				footer={
					<div className={styles.footer}>
						<Button
							onClick={() =>
								store.print.setPrintPreview({
									type: 'order-for-collect',
									ids: visit?.ordersCollect?.order_ids.map((el) => el) || [],
								})
							}
						>
							Печать списка товаров
						</Button>
						<Button onClick={responseItemsCollect} type="primary">
							Завершить сборку
						</Button>
					</div>
				}
			>
				<Table
					pagination={false}
					className={styles.table}
					columns={columns}
					dataSource={visit?.ordersCollect?.items?.map((el, i) => ({ ...el, key: i.toString() })) || []}
					rowClassName={({ collected_count, quantity }: IOrderCollectItem) => {
						if (collected_count !== quantity && collected_count !== 0) return styles.yellow;
						if (quantity === collected_count) return styles.green;
						return '';
					}}
				/>
				<div className={styles.sum}>
					<div>Сумма заказа</div>
					<div className={styles.total_price}>{visit?.ordersCollect?.total_price}</div>
				</div>
			</ModalScannedCounter>
			<ModalWarningCollect data={data} onClose={onClose} />
		</>
	);
};

export default observer(ModalOrderCollect);
