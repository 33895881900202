const audioCache: { [key: string]: HTMLAudioElement } = {
	error: new Audio('/sounds/error.mp3'),
	success: new Audio('/sounds/success.mp3'),
	warning: new Audio('/sounds/warning.mp3'),
	posted: new Audio('/sounds/posted.mp3'),
};

let timerId: any;

export const playAudio = (type: 'error' | 'success' | 'warning' | 'posted', time = 0): void => {
	const audio = audioCache[type];
	if (!audio.paused) audio.currentTime = 0;

	clearTimeout(timerId);

	timerId = setTimeout(() => {
		audio.play().catch((error) => console.error('Ошибка воспроизведения:', error));
	}, time);
};
