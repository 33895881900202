import Button from '@components/buttons/button';
import PostalInfoModal from '@components/postal-info-modal';
import colors from '@config/colors';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Order, Postal } from '@models/index';
import { Tag, Tooltip } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useState } from 'react';

import Actions, { ActionsProps } from './components/actions';
import PostalBriefInfo, { PostalBriefInfoProps } from './components/brief';
import styles from './index.module.scss';

export interface PostalTableProps {
	title: string;
	data: PostalTableDataType[];
	hasClearButton?: boolean;
	classTableWrap?: string;
	onButtonClick?: (buttonType: PostalTableDataType['buttonType'], key: number) => void;
	onActionClick: (
		action: Parameters<ActionsProps['onClick']>[0],
		postalId: number,
		type: PostalTableDataType['type']
	) => void;
}

export interface PostalTableDataType {
	key: number;
	header: PostalBriefInfoProps;
	names: { sender?: string; receiver: string };
	phones: { sender?: string; receiver: string };
	isEditPostal?: boolean;
	info: string[];
	tags?: string[];
	cell?: string | string[] | null;
	buttonType: 'process' | 'confirm-postal' | 'not-collected' | null;
	type: 'postal' | 'payment' | 'requistion' | 'order';
	postalObject?: Postal;
	orderObject?: Order;
}

const PostalTable: FC<PostalTableProps> = ({
	title,
	data,
	hasClearButton,
	classTableWrap,
	onButtonClick,
	onActionClick,
}) => {
	const visit = useCurrentVisit();
	const { operator } = useStore();
	const [selectedItem, setSelectedItem] = useState<PostalTableDataType | null>(null);

	return (
		<div className={styles.wrap}>
			<div className={styles.title}>
				<h3 className={styles.title__text}>{title}</h3>
			</div>
			<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
				<table className={cn(styles.table, classTableWrap)}>
					<tbody>
						{data.map((item) => {
							const {
								header,
								names,
								phones,
								type,
								key,
								tags,
								buttonType,
								postalObject,
								orderObject,
								isEditPostal,
								info: [str1, str2],
							} = item;

							return (
								<tr key={key} className={styles.row} onClick={() => setSelectedItem(item)}>
									<td className={styles.cell} width={150 + 16}>
										<PostalBriefInfo {...header} />
									</td>
									<td className={styles.cell} width={276 + 32}>
										<div className={cn({ [styles.bold]: type === 'requistion' })}>{names.sender}</div>
										<div className={cn({ [styles.bold]: type === 'postal' || type === 'payment' })}>
											{names.receiver}
										</div>
									</td>
									<td className={styles.cell} width={130 + 32}>
										<div>{phones.sender}</div>
										<div>{phones.receiver}</div>
									</td>
									<td className={styles.cell} width={150 + 32}>
										<div>{str1}</div>
										<div>{str2}</div>
									</td>
									<td className={styles.cell}>
										<div className={styles.container}>
											{(postalObject?.store_place_item_full_name ||
												!!orderObject?.store_place_item_full_names?.length) && (
												<div>
													{postalObject?.store_place_item_full_name && (
														<Tag className={cn(styles.tag, styles.tag__postal)}>
															{postalObject?.store_place_item_full_name}
														</Tag>
													)}
													{orderObject?.store_place_item_full_names?.map((tag) => (
														<Tag key={tag} className={cn(styles.tag, styles.tag__order)}>
															{tag}
														</Tag>
													))}
												</div>
											)}
											{orderObject?.is_adult ? <span className={styles.adult_mark}>18+</span> : null}
											{type !== 'payment' ? (
												<div className={styles.tags__wrap}>
													<div className={styles.tags}>
														{tags?.map((tag) => (
															<Tag key={tag} className={styles.tag}>
																{tag}
															</Tag>
														))}
													</div>
													<div className={styles.tags__other}>
														{buttonType && (
															<>
																{['default', 'process', operator && 'not-collected'].includes(buttonType) && (
																	<Button
																		type={header.isHighlight ? 'default' : 'primary'}
																		onClick={(evt) => {
																			evt.stopPropagation();
																			onButtonClick && onButtonClick(buttonType, key);
																		}}
																		className={styles.button}
																	>
																		{(buttonType === 'process' && 'Обработать') ||
																			(buttonType === 'not-collected' && 'Собрать заказ')}
																	</Button>
																)}
																{buttonType === 'confirm-postal' ? (
																	!!postalObject?.store_place_item_full_name &&
																	operator?.isRouting &&
																	!visit?.entryPostalCollectItems(header.number as string) ? (
																		<Button
																			onClick={(e) => {
																				e.stopPropagation();
																				visit?.getPostalForCollectParcels();
																			}}
																			className={styles.button}
																			type={header.isHighlight ? 'default' : 'primary'}
																		>
																			Достать с ячейки
																		</Button>
																	) : (
																		postalObject &&
																		!!postalObject.additional_services.is_completeness &&
																		postalObject.isRequireCompletenessConfirm &&
																		postalObject.market_status !== 96 && (
																			<Button
																				type={header.isHighlight ? 'default' : 'primary'}
																				onClick={(evt) => {
																					evt.stopPropagation();
																					onButtonClick && onButtonClick(buttonType, key);
																				}}
																				className={styles.button}
																			>
																				Проверка
																			</Button>
																		)
																	)
																) : null}
															</>
														)}
														{postalObject && postalObject.need_document === 1 && (
															<Tooltip
																title="Выдача на основании документа, удостоверяющего личность"
																className={styles.tooltip__card}
															>
																<Icon imageName="Card" color={colors.red} />
															</Tooltip>
														)}
													</div>
												</div>
											) : (
												<div className={cn(styles.tags__other, styles.other__payment)}>
													{postalObject && postalObject.need_document === 1 && (
														<Tooltip
															title="Выдача на основании документа, удостоверяющего личность"
															className={styles.tooltip__card}
														>
															<Icon imageName="Card" color={colors.red} />
														</Tooltip>
													)}
												</div>
											)}
										</div>
									</td>
									<td className={styles.cell} width={84 + 16}>
										<Actions
											id={header.id}
											type={type}
											isEditPostal={!!isEditPostal}
											onClick={(action) => onActionClick(action, header.id, type)}
											postal={postalObject}
											order={orderObject}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</OverlayScrollbarsComponent>
			{hasClearButton && !!visit?.newReceptions.length && (
				<Button
					className={styles.clear}
					shape="round"
					icon={<Icon imageName="Delete" />}
					onClick={visit?.clearNewReceptions}
				>
					Удалить из визита все необработанные заявки ({visit?.newReceptions.length})
				</Button>
			)}
			<PostalInfoModal
				id={selectedItem?.key || null}
				type={selectedItem?.type as 'postal' | 'order'}
				onClose={() => setSelectedItem(null)}
			/>
		</div>
	);
};

export default observer(PostalTable);
