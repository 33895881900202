import Button from '@components/buttons/button';
import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import PrintMenu from '@pages/main/acceptance/components/print-menu';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const Header: FC = () => {
	const {
		acceptance: {
			postal,
			postal: {
				postalCargoSpaces: {
					modalItems,
					getModalItemsShared,
					scannedCount,
					getCounterError,
					getCounterSurplus,
					getTotalItem,
				},
			},
		},
	} = useStore();

	const [barcodeModal, setBarcodeModal] = useState(false);
	const [barcode, setBarcode] = useState<string>('');
	const [barcodeError, setBarcodeError] = useState('');

	const closeBarcodeModal = (): void => {
		setBarcodeModal(false);
		setBarcode('');
		setBarcodeError('');
	};

	return (
		<>
			<header className={styles.header}>
				{![81].includes(
					postal.postalCargoSpaces.postalCargoSpacesItems?.routing_spaces?.find(
						(el: any) => el.number === modalItems?.number
					)?.status as number
				) && (
					<div>
						<Button className={styles.button__code} type="text" onClick={() => setBarcodeModal(true)}>
							Ввести трек-номер вручную
						</Button>
						<PrintMenu isShowNewCell={false} />
					</div>
				)}
				{postal.postalCargoSpaces.isNotFoundCargoSpaces && (
					<div className={styles.not_found}>Отсканированное грузоместо поступило ошибочно. Передайте на склад</div>
				)}
				<span className={styles.counter}>
					<span className={styles.counter__current}>
						{scannedCount || 0}
						{!!getModalItemsShared.length && !!getCounterSurplus && (
							<span className={styles.counter__surplus}>+{getCounterSurplus}</span>
						)}
						{!!getModalItemsShared.length && !!getCounterError && (
							<span className={styles.counter__error}>+{getCounterError}</span>
						)}
					</span>
					<span>/{getTotalItem}</span>
				</span>
			</header>
			<Modal
				classNameModal={styles.modal}
				className={styles.modal__body}
				title={null}
				okText="Добавить"
				okButtonProps={{ disabled: !!barcodeError || !barcode }}
				cancelButtonProps={{ style: { display: 'none' } }}
				open={barcodeModal}
				onCancel={closeBarcodeModal}
				onOk={() => {
					if (!barcode) return;
					postal
						?.scanBarcode(barcode.toUpperCase())
						.then(closeBarcodeModal)
						.catch((err: any) => setBarcodeError(err));
				}}
				hasLines={false}
				centered
				width={416}
				closable={false}
				destroyOnClose
			>
				<h3 className={styles.modal__title}>Введите трек-номер</h3>
				<Input isTransliterate className={styles.input} value={barcode} onChange={(e) => setBarcode(e.target.value)} />
				{barcodeError ? <p className={styles.error}>{barcodeError}</p> : null}
			</Modal>
		</>
	);
};

export default observer(Header);
