import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import { IProduct } from '@store/acceptance/emall';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const ItemsTable: FC = () => {
	// const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const {
		acceptance: {
			emall: { modalItemsProduct },
		},
	} = useStore();

	if (!modalItemsProduct?.items?.length) return <NoData text="Товаров нет" />;

	const { items } = modalItemsProduct;

	const columns: ColumnsType<IProduct> = [
		{
			title: <span>Код товара</span>,
			dataIndex: 'code',
			className: styles.cell,
			width: 150,
			render: (_, { item_market_id: id }) => <span>{id}</span>,
		},
		{
			title: <span>Наименование</span>,
			dataIndex: 'name',
			className: styles.cell,
			render: (_, { name }) => <span>{name}</span>,
		},
		{
			title: <span>Ячейка</span>,
			dataIndex: 'place',
			className: styles.cell,
			width: 150,
			render: (_, { store_place_item_full_name: name }) => <span>{name}</span>,
		},
		// {
		// 	dataIndex: 'action',
		// 	className: styles.cell,
		// 	width: 28,
		// 	render: (_, item) => (
		// 		<span style={{ width: 136 }}>
		// 			<IconButton imageName="Close" fontSize={20} />
		// 			{/* {item.is_scanned || item.isNewError ? (
		// 				<IconButton imageName="Close" fontSize={20} onClick={() => modalItems.deleteItem(item)} />
		// 			) : (
		// 				''
		// 			)} */}
		// 		</span>
		// 	),
		// },
	];

	return (
		<Table
			pagination={false}
			columns={columns}
			dataSource={items.map((item) => ({ ...item, key: item.id }))}
			onRow={({ isSurplus }) => ({
				className: cn(styles.row, {
					// [styles.row__error]: isNewError,
					[styles.row__new]: isSurplus,
				}),
			})}
			onHeaderRow={() => ({ className: styles.row })}
			className={styles.table}
			locale={{ emptyText: 'Нет данных' }}
			expandable={{
				expandedRowClassName: () => cn(styles.row, styles.row__expand, styles.row__error),
				showExpandColumn: false,
				expandedRowRender: () => (
					<span className={styles.error}>
						Отсканированный пакет поступил ошибочно. Проинформируйте об этом PostHelp и передайте пакет на склад.
					</span>
				),
				// rowExpandable: ({ isNewError, key }) => {
				// 	if (isNewError && key && !expandedRowKeys.includes(key)) setExpandedRowKeys((value) => [...value, key]);
				// 	return !!isNewError;
				// },
				// expandedRowKeys,
			}}
		/>
	);
};

export default observer(ItemsTable);
