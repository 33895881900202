import { ShipmentService } from '@services/shipment';
import { ShipmentPostalService } from '@services/shipment-postal';
import { flow, getRoot, types } from 'mobx-state-tree';

import { playAudio } from '../../../utils/playSound';
import { RootStore } from '../..';
import Postal from './postal';

const { model, array, maybeNull } = types;

const ParcelsModel = model('parcels-model', {
	items: maybeNull(array(Postal)),
	modalAdd: false,
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		loadItems: flow(function* () {
			root.setLoading(true);
			self.items = null;

			try {
				const res = yield ShipmentPostalService.getPostalItems();
				self.items = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		showModalAdd(value: boolean) {
			self.modalAdd = value;
		},
		deleteItem: flow(function* (id: number) {
			root.setLoading(true);
			try {
				yield ShipmentService.deleteEmallItem(id);
				root.shipment.post.parcels.loadItems();
				playAudio('success');
			} catch (err: any) {
				playAudio('error');
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
	};
});

export default ParcelsModel;
