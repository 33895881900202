import IconButton from '@components/buttons/icon_btn';
import { INotice } from '@store/notice';
import { Button, message } from 'antd';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export const useMessage: (props?: { duration?: number }) => any = (props) => {
	const [messageApi, contextHolder] = message.useMessage({
		top: 64,
		duration: props?.duration || 7,
		prefixCls: 'messages',
	});

	const key = new Date().getTime();

	const copyToClipboard = (message: string): void => {
		const textarea = document.createElement('textarea');
		textarea.value = message;
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);
	};

	const open = ({ message, hasButton, type }: INotice): ReturnType<typeof messageApi.open> =>
		messageApi.open({
			key,
			type,
			content: (
				<>
					<OverlayScrollbarsComponent options={{ scrollbars: { autoHide: 'move' } }} className="message__scroll">
						{message || ''}
					</OverlayScrollbarsComponent>
					{hasButton && (
						<Button type="primary" onClick={() => copyToClipboard(message || '')}>
							Копировать
						</Button>
					)}
					<IconButton imageName="Close" onClick={() => messageApi.destroy(key)} className="message__close" />
				</>
			),
			className: 'message',
		});

	return [open, contextHolder];
};
