let timerId: any;

export const voiceMessage = (data?: { message?: string; isSave?: boolean }, time = 500): void => {
	clearTimeout(timerId);

	if (!data) return speechSynthesis.speak(new SpeechSynthesisUtterance(localStorage.getItem('voiceMessage') || ''));

	const { message, isSave } = data;

	isSave && localStorage.setItem('voiceMessage', message || '');
	timerId = setTimeout(() => {
		speechSynthesis.speak(new SpeechSynthesisUtterance(message || localStorage.getItem('voiceMessage') || ''));
	}, time);
};
