/* eslint-disable @typescript-eslint/no-empty-interface */
import OrderService from '@services/order';
import { PostalService } from '@services/postal';
import OrdersCollect from '@store/visits/order-collect';
import { flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { playAudio } from '../../utils/playSound';
import { RootStore } from '..';

const { model, number, array, string, boolean, maybeNull } = types;

const EmallTask = model('order', {
	id: number,
	market_id: number,
	expired_days: number,
	can_extended_for: number,
	receiver_fio: string,
	receiver_phone_number: string,
	bags: array(model('bag', { id: number, market_id: number, name: string })),
	has_packages: boolean,
});

const MailTask = model('postal-item', {
	isScan: false,
	id: number,
	number: string,
	task_status_name: maybeNull(string),
	is_juristic: number,
	is_processed: number,
	receiver_fio: string,
	receiver_phone_number: string,
	sender_fio: string,
	sender_phone_number: maybeNull(string),
	weight: number,
	width: number,
	height: number,
	length: number,
	type: number,
	store_place_item_full_name: maybeNull(string),
	additional_services: model('additional_services', {
		is_fragile: number,
		is_inventory: number,
		is_completeness: number,
		is_oversize: number,
		is_declared: number,
		is_cash_on: number,
	}),
}).actions((self) => ({
	setScan(value: boolean) {
		self.isScan = value;
	},
}));

export const TasksModel = model('Tasks', {
	postal_quantity: number,
	order_quantity: number,
	postal_items: array(MailTask),
	orders: array(EmallTask),
	search: maybeNull(string),
	showPostalsModal: false,
	ordersCollect: maybeNull(OrdersCollect),
})
	.actions((self) => ({
		setSearch(value: string) {
			self.search = value;
		},
		setShowPostalsModal(value: boolean) {
			self.showPostalsModal = value;
		},
		postalCollect: flow(function* (postals: IMailTask[]) {
			const root = getRoot<typeof RootStore>(self);

			try {
				const ids = postals.map(({ id }) => id) || [];
				yield PostalService.postPostalCollect(ids);
				root.loadTask();
				playAudio('success');
			} catch (err: any) {
				playAudio('error');
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			}
		}),
		setOrdersCollect: flow(function* (isSingle: boolean, id: number) {
			const root = getRoot<typeof RootStore>(self);
			root.setLoading(true);

			const ids: number[] = isSingle
				? [id]
				: root.tasks.filteredOrders.filter(({ has_packages }) => !has_packages).map((el) => el.id);

			try {
				const res = yield OrderService.getOrderForCollect(ids);
				self.ordersCollect = res.data;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		closeModalOrderCollect() {
			const root = getRoot<typeof RootStore>(self);
			root.loadTask();
			self.ordersCollect = null;
		},
		handleScanner(evt: KeyboardEvent) {
			const root = getRoot<typeof RootStore>(self);

			root.scanner.handleScanner(evt, (value) => {
				if (self.showPostalsModal) {
					const isEqualItem = root.tasks?.filteredPostals.some((el) => el.number === value && el.isScan);
					const item = root.tasks?.filteredPostals.find(({ number }) => number === value);
					item?.setScan(true);
					playAudio(isEqualItem ? 'warning' : 'success');
					return;
				}

				if (self.ordersCollect) {
					const response = flow(function* () {
						try {
							root.setLoading(true);

							const res = yield OrderService.postOrderScan({
								barcode: value,
								orders: self.ordersCollect?.order_ids || [],
							});

							if (res.data.status !== 1) {
								playAudio('error');
								root.notice.setNotice({ message: res.data.status_message, type: 'error' });
							} else {
								playAudio('success');
							}

							yield root.tasks.setOrdersCollect(
								self.ordersCollect?.order_ids.length === 1,
								Number(self.ordersCollect?.order_ids[0])
							);
						} catch (err: any) {
							playAudio('error');
							root.notice.setNotice({ message: err?.error || err?.message || '' });
						} finally {
							root.setLoading(false);
						}
					});

					response();
				}
			});
		},
	}))
	.views((self) => ({
		get tasksAmount() {
			return self.postal_items.length + self.orders.length;
		},
		get orderAmount() {
			return self.orders.length;
		},
		get postalAmount() {
			return self.postal_items.length;
		},
		get filteredOrders() {
			return self.orders.filter(
				({ market_id, receiver_fio }) =>
					String(market_id)
						.toLowerCase()
						.includes(self.search?.toLowerCase() || '') ||
					receiver_fio.toLocaleLowerCase().includes(self.search?.toLocaleLowerCase() || '')
			);
		},
		get filteredPostals() {
			return self.postal_items.filter(
				({ number, receiver_fio, sender_fio }) =>
					number.toLowerCase().includes(self.search?.toLowerCase() || '') ||
					receiver_fio.toLocaleLowerCase().includes(self.search?.toLocaleLowerCase() || '') ||
					sender_fio.toLocaleLowerCase().includes(self.search?.toLocaleLowerCase() || '')
			);
		},
		get scannedPostals() {
			return self.postal_items.filter(({ isScan }) => isScan);
		},
	}));

export interface ITask extends SnapshotIn<typeof TasksModel> {}
export interface IEmallTask extends SnapshotIn<typeof EmallTask> {}
export interface IMailTask extends SnapshotIn<typeof MailTask> {}
