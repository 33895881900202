/* eslint-disable @typescript-eslint/no-empty-interface */
import { destroy, getParent, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { playAudio } from '../utils/playSound';
import { RootStore } from '.';

const { model, maybeNull, union, array, string, literal, number } = types;

export type PutSpaceDataType = {
	routing_list_id?: number | null;
	number?: string;
	is_scanned?: 0 | 1;
};

const SpaceRouting = model('spaces', {
	id: number,
	type: number,
	is_scanned: union(literal(0), literal(1)),
	number: string,
	routing_items_count: number,
	status: maybeNull(number),
	isError: false,
	isSurplus: false,
});

const RoutingInfo = model('routing-info', {
	id: maybeNull(number),
	status: maybeNull(number),
	created_at: maybeNull(string),
	transferred_at: maybeNull(string),
	shipped_at: maybeNull(string),
	arrival_at: maybeNull(string),
	accepted_at: maybeNull(string),
	driver_fio: maybeNull(string),
	driver_phone: maybeNull(number),
	car_plate: maybeNull(string),
	is_mercenary: maybeNull(union(literal(0), literal(1))),
	counts: model('counts', {
		bags: number,
		bags_scanned: number,
		postals: number,
		postals_scanned: number,
		boxes: number,
		boxes_scanned: number,
		goods: number,
		goods_scanned: number,
		money_bags: number,
		money_bags_scanned: number,
		total_scanned: number,
		total: number,
	}),
	routing_spaces: array(SpaceRouting),
	routing_list_id: maybeNull(array(number)),
	confirmOpened: false,
})
	.actions((self) => {
		const root = getRoot<typeof RootStore>(self);
		const parent = getParent<any>(self);

		return {
			findSpace(number: string, data: PutSpaceDataType, opt?: { hasSurplus?: boolean; isDelete?: boolean }) {
				const space = self.routing_spaces.find(
					(item) => item.number.toLocaleUpperCase() === number.toLocaleUpperCase()
				);

				if (space) {
					parent?.setPostalNumber && parent.setPostalNumber(null);

					if (space.isSurplus || space.isError) {
						playAudio('warning');
						if (opt?.isDelete) destroy(space);
						return;
					}

					const currentData: PutSpaceDataType = number.toLocaleUpperCase().includes('BY') ? { ...data, number } : data;

					if (number.toLocaleUpperCase().includes('BY')) {
						parent?.setPostalNumber && parent.setPostalNumber(number);
					}

					parent?.setSpace && parent.setSpace(space.id, currentData);
				} else {
					if (root.acceptance.modalInfoAttachCell) {
						parent?.scanCell && parent.scanCell(number);
						return;
					}
					if (opt?.hasSurplus) {
						parent?.setSpace && parent.addSpace(number);
						parent?.setPostalNumber && parent.setPostalNumber(null);
						return;
					}
					playAudio('error');
					root.setMessage({ title: 'Отсканированное грузоместо не найдено', description: '' });
				}
			},
			setConfirmOpened(value: boolean) {
				self.confirmOpened = value;
			},
		};
	})
	.views((self) => ({
		get isView() {
			if (!self.status) return false;
			return ![10, 40].includes(self.status);
		},
		get hasScanned() {
			return self.routing_spaces.filter(({ is_scanned }) => is_scanned).length > 0;
		},
		get submitDisabled() {
			return !self.driver_fio || self.routing_spaces.filter(({ is_scanned }) => is_scanned).length === 0;
		},
		get surplusCount() {
			return self.routing_spaces.filter(({ isSurplus }) => isSurplus).length;
		},
		get errorCount() {
			return self.routing_spaces.filter(({ isError }) => isError).length;
		},
		get hasUnscanned() {
			return self.routing_spaces.filter(({ is_scanned }) => !is_scanned).length > 0;
		},
	}));

export interface ISpaceRouting extends SnapshotIn<typeof SpaceRouting> {}
export interface IRoutingInfo extends SnapshotIn<typeof RoutingInfo> {}
export default RoutingInfo;
