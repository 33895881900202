/* eslint-disable @typescript-eslint/no-empty-interface */
import { ClientService } from '@services/client';
import { KassaService } from '@services/kassa';
import LogService from '@services/log';
import OrderService from '@services/order';
import PaymentService from '@services/payment';
import { PostalService } from '@services/postal';
import SearchService, { SearchResp } from '@services/search';
import OrdersCollect from '@store/visits/order-collect';
import { applySnapshot, cast, flow, getRoot, getSnapshot, Instance, types } from 'mobx-state-tree';
import { round } from 'utils/round';

import { playAudio } from '../../utils/playSound';
import { RootStore } from '..';
import CreateIncident, { CreateIncidentModel } from './create-incident';
import CreateOrder from './create-order';
import IncidentList from './incidents';
import Order from './order';
import OrderItems, { OrderItem } from './order-items';
import Payment from './payment';
import Postal from './postal';
import RefusalReasonsModel, { RefusalReasonType } from './refusal-reasons';

const { model, array, maybeNull, map, boolean, number, optional, string, union, literal } = types;
const allFilterTypes = [-1, 0, 1, 2, 3];
const filterTypesModel = optional(array(number), allFilterTypes);

const LoadData = model('LoadData', {
	id: maybeNull(number),
	type: maybeNull(union(literal('order'), literal('postal'))),
	code: maybeNull(number),
});

const Visit = model('Visit', {
	postals: array(Postal),
	orders: array(Order),
	initialReceivedQuantity: array(number),
	postalsForCollectParcels: maybeNull(array(Postal)),
	postalCollectScannedItems: maybeNull(array(string)),
	isShowModalParcels: optional(boolean, false),
	ordersCollect: maybeNull(OrdersCollect),
	isIdentification: optional(boolean, false),
	createOrderModel: maybeNull(CreateOrder),
	processedPostal: maybeNull(Postal),
	confirmedInventory: map(boolean),
	payment: maybeNull(Payment),
	paidInCash: maybeNull(number),
	paidInCard: maybeNull(number),
	orderItems: maybeNull(OrderItems),
	icidents: optional(IncidentList, { id: null, list: null }),
	createIncidentModel: maybeNull(CreateIncident),
	confirmModal: maybeNull(string),
	filterTypes: filterTypesModel,
	clearedIdPostals: optional(array(number), []),
	clearedIdOrders: optional(array(number), []),
	startLoadData: maybeNull(LoadData),
	isDefaultSearch: true,
	loadingPayment: false,
	sendRunning: false,
	showModalUnionCheck: false,
	extendedSearch: false,
	refusalReasons: maybeNull(RefusalReasonsModel),
	modalCorrectionId: maybeNull(number),
	isAdultConfirmed: false,
})
	.actions((self) => {
		let controller: AbortController | null = null;
		let timeoutId: any;

		return {
			scheduleLoadPayment() {
				clearTimeout(timeoutId);
				self.loadingPayment = true;
				timeoutId = setTimeout(this.loadPayment, 500);
			},
			loadPayment: flow(function* (isRepeat?: boolean) {
				controller && controller.abort();
				controller = new AbortController();

				const root = getRoot<typeof RootStore>(self);
				const postalIds = self.postals.map(({ id }) => id);
				const orderIds = self.orders.map(({ id }) => id);
				root.setPaymentError(false);

				try {
					const res = yield PaymentService.getPayment(orderIds, postalIds, controller.signal);
					controller = null;

					if (res.data.error) {
						root.setPaymentError(true);
						root.notice.setNotice({
							message:
								String(res.data.error?.error || `Ошибка в /payment:: ${JSON.stringify(res.data.error)}`) +
								' - статус 200',
						});
					} else {
						self.payment = res.data;
					}
				} catch (error: any) {
					if (error.message === 'canceled') return;

					LogService.postLog({
						market_id: self.startLoadData?.id || self.startLoadData?.code || null,
						model_type: self.startLoadData?.type || '',
						request:
							'/payment' +
							(!(error.response?.data?.error || error.response?.data?.message) ? ` - isRepeat: ${isRepeat}` : ''),
						response: JSON.stringify(error?.response || error || 'request: /payment'),
					}).catch((err) => console.log('log', err));

					if (!isRepeat && !(error.response?.data?.error || error.response?.data?.message)) {
						//@ts-ignore
						return yield self.loadPayment(true);
					}

					root.setPaymentError(true);
					root.notice.setNotice({
						message: String(
							error.response?.data?.error ||
								error.response?.data?.message ||
								`Ошибка в /payment: ${JSON.stringify(error?.response)}; !!!error: ${JSON.stringify(error)}`
						),
					});
				} finally {
					self.loadingPayment = false;
				}
			}),
			getInfoIdentification: flow(function* (data: { phone_number: string; surname: string }) {
				const root = getRoot<typeof RootStore>(self);

				try {
					const res = yield ClientService.getInfoIdentification(data);
					self.isIdentification = Boolean(res.data.status);
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				}
			}),
		};
	})
	.actions((self) => {
		const root = getRoot<typeof RootStore>(self);
		let highlighTimeoutId: any;
		let delayTimeoutId: any;
		let highlightTr: HTMLElement | null;

		return {
			clearVisit() {
				if (self.orders.length > 0) {
					self.orders.clear();

					if (self.postals.length > 0) {
						self.loadPayment();
						return;
					}
				}

				self.confirmModal = 'Визит успешно обработан';

				if (self.postals.length > 0) {
					const paymentIds: number[] = [];
					const jurIds: number[] = [];
					const fizIds: number[] = [];
					const fizPostalIds: number[] = [];
					const refundIds: number[] = [];

					const print = (ids: number[], type: string): void => {
						if (!ids.length) return;
						root.print.printTask({ ids, type });
					};

					self.postals.forEach(({ type, is_juristic, market_status: status, id }) => {
						if (type === 2) paymentIds.push(id);
						if (type === 0 && is_juristic === 1) jurIds.push(id);
						if (type === 0 && is_juristic === 0) fizIds.push(id);
						if (type === 1 && status !== 96) fizPostalIds.push(id);
						if (type === 1 && status === 96) refundIds.push(id);
					});

					print(refundIds, 'refund-receipt');
					print(paymentIds, 'get-group-issuing-cash-on-delivery');
					print(jurIds, 'get-acceptance-for-legal-entities');
					print(fizIds, 'get-acceptance-for-individuals');
					print(fizPostalIds, 'get-issuance-for-individuals');

					self.postals.clear();
				}
			},
			setOrdersCollect: flow(function* (isSingle: boolean, id: number) {
				const ids: number[] = isSingle ? [id] : self?.orders.filter((el) => el.is_collected === 0).map((el) => el.id);
				try {
					root.setLoading(true);
					const res = yield OrderService.getOrderForCollect(ids);

					self.ordersCollect = res.data;
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			closeModalOrderCollect() {
				self.ordersCollect = null;
			},
			setStartLoadData(
				data: { id?: number; type?: 'order' | 'postal'; code?: number },
				isDefaultSearch: boolean | null
			) {
				if (isDefaultSearch !== null) self.isDefaultSearch = isDefaultSearch;
				self.startLoadData = LoadData.create(data);

				return this.loadPostals();
			},
			loadPostals: flow(function* () {
				if (!self.startLoadData) return;

				try {
					root.setLoading(true);
					const res = self.startLoadData.code
						? yield SearchService.findAdditionalByCode(self.startLoadData.code)
						: self.startLoadData.id &&
						  self.startLoadData.type &&
						  (yield SearchService.findAdditional(self.startLoadData.id, self.startLoadData.type));

					if (
						res.data.postalItems[0] &&
						!self.startLoadData?.code &&
						!res.data.postalItems?.some(
							({ type, is_juristic }: { type: number; is_juristic: number }) => type === 2 || is_juristic
						)
					) {
						yield self.getInfoIdentification({
							phone_number: res.data.postalItems[0].receiver.phone_number,
							surname: res.data.postalItems[0].receiver.surname,
						});
					} else {
						self.isIdentification = false;
					}

					self.postals = self.clearedIdPostals.length
						? res.data.postalItems.filter((item: Instance<typeof Postal>) => !self.clearedIdPostals.includes(item.id))
						: res.data.postalItems;

					self.orders = self.clearedIdOrders.length
						? res.data.orders.filter((item: Instance<typeof Order>) => !self.clearedIdOrders.includes(item.id))
						: res.data.orders;

					if (self.filterTypes.length < 5) {
						allFilterTypes
							.filter((type) => !self.filterTypes.includes(type))
							.forEach((type) => {
								if (type === 3) {
									self.orders.clear();
									return;
								}

								type >= 0 && self.postals.forEach((postal) => postal.type === type && self.postals.remove(postal));
							});
					}

					const warrantyItems: string[] = [];
					const orderIds: number[] = [];

					self.orders.forEach(({ items, market_id: id }) =>
						items.forEach(({ is_warranty_card, name }) => {
							if (is_warranty_card) {
								warrantyItems.push(name);
								!orderIds.includes(id) && orderIds.push(id);
							}
						})
					);

					if (warrantyItems.length) {
						root.setMessage({
							title: 'Гарантия',
							description: '',
							html: `<p>
								Внимание! В заказе ${orderIds.join(', ')} есть товар с гарантийным талоном:</br>
								${warrantyItems.join(', ')}.</br>
								Проверьте наличие гарантийного талона и заполните его
							</p>`,
						});
					}

					self.paidInCash = null;
					self.paidInCard = null;
					self.scheduleLoadPayment();
				} catch (err: any) {
					if (self.startLoadData?.code) return Promise.reject(err);
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			createOrder() {
				self.createOrderModel = CreateOrder.create();
			},
			cancelOrder() {
				self.createOrderModel = null;
				this.endProcessPostal(true);
			},
			clear() {
				self.orders.clear();
				self.postals.clear();
				self.clearedIdPostals.clear();
				self.clearedIdOrders.clear();
				self.startLoadData = null;
				self.filterTypes = allFilterTypes as Instance<typeof filterTypesModel>;
			},
			processPostal: flow(function* (postalId: number) {
				const root = getRoot<typeof RootStore>(self);

				try {
					root.setLoading(true);
					const postal = yield PostalService.getPostalInfo(postalId);
					const dataPostal = postal.data;

					self.processedPostal = Postal.create(dataPostal);
					if (dataPostal?.packing[0]?.quantity) {
						dataPostal?.packing?.forEach((el: any) => {
							self?.processedPostal?.setPackaging({
								packing_id: el.packing_id,
								packing_item_id: el.postal_item_id,
								sum: el.total_price,
								quantity: Number(el.quantity),
								name: el.name,
							});
						});
					}

					yield self.processedPostal.setCalculate();
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			endProcessPostal(noLoad?: boolean) {
				!noLoad && this.loadPostals();
				if (self.createOrderModel?.clientId) {
					self.createOrderModel = null;
				}
				self.processedPostal = null;
			},
			deletePostal(postalId: number) {
				const postal = self.postals.find(({ id }) => id === postalId);
				if (postal) {
					self.clearedIdPostals.push(postal.id);
					self.postals.remove(postal);
					self.scheduleLoadPayment();
				}
			},
			deletePostalByObj(postal: Instance<typeof Postal>) {
				self.postals.remove(postal);
			},
			changePostal(postalId: number, items: any) {
				const postalIndex = self.postals.findIndex(({ id }) => id === postalId);
				const postal = self.postals[postalIndex];

				if (postal) {
					applySnapshot(self.postals[postalIndex], { ...postal, ...items });
				}
			},
			deleteOrder(orderId: number) {
				const order = self.orders.find(({ id }) => id === orderId);
				if (order) {
					self.clearedIdOrders.push(order.id);
					self.orders.remove(order);
					self.scheduleLoadPayment();
				}
			},
			confirmInventory(id: number, value: boolean) {
				self.confirmedInventory.set(String(id), value);
			},
			setErrorOrders(data: { success_ids: number[]; error_ids: number[] }) {
				self.orders.forEach((order) => {
					if (data?.success_ids.includes(order.id)) {
						self.orders.remove(order);
					}

					if (data?.error_ids.includes(order.id)) {
						order.status_name = 'Ошибка';
						order.status_id = 90;
					}
				});

				self.scheduleLoadPayment();
			},
			confirmOrders: flow(function* (union: 0 | 1) {
				if (self.sendRunning) return;

				self.sendRunning = true;
				root.kassa.setPrintStatus('process');

				try {
					yield KassaService.sendReceiptOrder({
						orders: self.orders.map(({ id }) => id),
						cash_payment: self?.paidInCash || 0,
						cashless_payment: self?.paidInCard || 0,
						union,
					});
				} catch (err: any) {
					root.kassa.setPrintStatus(null);
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					self.sendRunning = false;
				}
			}),
			clearNewReceptions() {
				self.postals.forEach((item) => {
					if (item.type === 0 && item.market_status === 0) {
						self.postals.remove(item);
					}
				});
				self.scheduleLoadPayment();
			},
			setErrorPostals(data: { success_ids: number[]; error_ids: number[] }) {
				self.postals.forEach((postal) => {
					if (data?.success_ids.includes(postal.id)) {
						self.postals.remove(postal);
					}

					if (data?.error_ids.includes(postal.id)) {
						postal.market_status_name = 'Ошибка';
					}
				});
				self.scheduleLoadPayment();
			},
			setIsShowModalCollectParcels(value: boolean): void {
				if (!value) {
					playAudio('success');
				}
				self.isShowModalParcels = value;
			},
			close: flow(function* () {
				if (self.sendRunning) return;

				self.sendRunning = true;
				root.kassa.setPrintStatus('process');

				try {
					yield KassaService.sendReceiptPostal({
						postal_items: self.postals.map(({ id }) => id),
						cash_payment: self?.paidInCash || 0,
						cashless_payment: self?.paidInCard || 0,
					});
				} catch (err: any) {
					root.kassa.setPrintStatus(null);
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					self.sendRunning = false;
				}
			}),
			payCash(value: number | null) {
				self.paidInCash = value;
			},
			payCard(value: number | null) {
				self.paidInCard = value;
			},
			loadOrderItems: flow(function* (
				data: { orderId: number; itemId?: number; count?: number },
				request: 'getOrderItems' | 'changeOrderItem' | 'cancelCorrection' | 'cancelOrder'
			) {
				try {
					root.setLoading(true);
					const res = yield OrderService[request](data);
					self.orderItems = res.data || null;
					if (request === 'getOrderItems') {
						self.initialReceivedQuantity = res.data?.items.map((el: OrderItem) => el.received_quantity);
					}
					self.refusalReasons?.updateItemsToCorrect();
					self.scheduleLoadPayment();
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			setConfirmModal(message: string | null) {
				self.confirmModal = message;
			},
			createIncident(props?: Partial<CreateIncidentModel>) {
				self.createIncidentModel = CreateIncident.create({ store: root.postOffice, ...props });
			},
			cancelIncident() {
				self.createIncidentModel = null;
			},
			setFilterTypes(type: number) {
				if (type === -1) {
					self.filterTypes = allFilterTypes as Instance<typeof filterTypesModel>;
					this.loadPostals();
					return;
				}

				if (self.filterTypes.includes(type)) {
					self.filterTypes.remove(type);
					self.filterTypes.remove(-1);
				} else {
					self.filterTypes.push(type);
					self.filterTypes.length === 4 && self.filterTypes.push(-1);
				}

				this.loadPostals();
			},
			setShowModalUnionCheck(value?: boolean) {
				const needUnion = self.orders.filter(({ payment_type }) => payment_type === 1).length > 1;

				self.showModalUnionCheck = value ?? needUnion;
				!needUnion && this.confirmOrders(1);
			},
			highlightPostal(value: string): boolean {
				clearTimeout(highlighTimeoutId);
				clearTimeout(delayTimeoutId);

				highlightTr && highlightTr.closest('tr')?.classList.remove('search-item');

				const numberPostal = self.postals.find(({ number, setIsHighlight }) => {
					if (number.includes(value)) {
						setIsHighlight(true);
						return true;
					}
				})?.number;

				const numberOrder = self.orders.find(({ market_id: id, setIsHighlight }) => {
					if (`${id}`.includes(value)) {
						setIsHighlight(true);
						return true;
					}
				})?.market_id;

				const number = numberPostal || (numberOrder && `${numberOrder}`) || '';

				highlightTr = document.getElementById(number);
				if (!highlightTr) return false;

				delayTimeoutId = setTimeout(() => {
					highlightTr?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
					clearTimeout(delayTimeoutId);
				}, 100);

				highlightTr.closest('tr')?.classList.add('search-item');

				highlighTimeoutId = setTimeout(() => {
					highlightTr && highlightTr.closest('tr')?.classList.remove('search-item');

					if (numberPostal) {
						self.postals.forEach(({ number, setIsHighlight }) => {
							if (number === numberPostal) setIsHighlight(false);
						});
					}

					if (numberOrder) {
						self.orders.find(({ market_id: id, setIsHighlight }) => {
							if (id === numberOrder) setIsHighlight(false);
						});
					}

					clearTimeout(highlighTimeoutId);
				}, 15000);
				return true;
			},
			getPostalInfoMessage: flow(function* (number: string) {
				root.setLoading(true);

				try {
					const { data: postalInfo } = yield PostalService.getPostalInfoByNumber(number);
					root.setMessage({
						title: `Отправление ${postalInfo.number} в статусе ${postalInfo.market_status_name}`,
						description: '',
					});
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			getPostalForCollectParcels() {
				const filteredPostals = self.postals
					.filter((el) => el.type === 1)
					.map((el) => {
						const postalSnapshot = getSnapshot(el);
						return Postal.create(postalSnapshot);
					});

				self.postalsForCollectParcels = cast(filteredPostals);
				self.isShowModalParcels = true;
			},
			setPostalCollectScannedItems() {
				self.postalCollectScannedItems = null;
			},
			findPostalStepFinal(postal: Instance<typeof Postal>, number: string) {
				if (postal.type === 0 && postal.market_status === 0) {
					this.processPostal(postal.id);
				} else {
					this.highlightPostal(number);
				}
			},
			findPostalStep2: flow(function* (number: string) {
				const {
					data: { postalItems },
				}: { data: SearchResp } = yield SearchService.findPostal(number);

				const postalItem = postalItems.find((item) => item?.number === number);
				if (postalItem?.id) {
					//@ts-ignore
					yield self.setStartLoadData({ id: postalItem.id, type: 'postal' }, true);
					const postal = self.postals.find((item) => item.number === number);

					if (postal) {
						//@ts-ignore
						self.findPostalStepFinal(postal, number);
					} else {
						//@ts-ignore
						self.getPostalInfoMessage(number);
					}
				} else {
					//@ts-ignore
					self.getPostalInfoMessage(number);
				}
			}),
			findPostal(number: string) {
				if (!number) return;
				root.setLoading(true);

				if (self.postals.length) {
					const postal = self.postals.find((item) => item.number === number);
					if (postal) {
						this.findPostalStepFinal(postal, number);
					} else {
						//@ts-ignore
						self.findPostalStep2(number);
					}
				} else {
					//@ts-ignore
					self.findPostalStep2(number);
				}
			},
			setExtendedSearch(value: boolean) {
				self.extendedSearch = value;
			},
			mountRefusalReasons(type: RefusalReasonType, id: number) {
				self.refusalReasons = RefusalReasonsModel.create({ type, id });
				self.refusalReasons.loadReasons();
			},
			unmountRefusalReasons() {
				self.refusalReasons = null;
			},
			setModalCorrectionId(id: number | null) {
				self.modalCorrectionId = id;
			},
			confirmAdult(value: boolean) {
				self.isAdultConfirmed = value;
			},
		};
	})
	.views((self) => ({
		get isEmpty() {
			return !self.orders.length && !self.postals.length;
		},
		get issues() {
			return [...self.postals.filter(({ type }) => [1, 2].includes(type)), ...self.orders];
		},
		get receptions() {
			return self.postals.filter(({ type }) => [0].includes(type));
		},
		entryPostalCollectItems(value: string) {
			return self.postalCollectScannedItems ? self.postalCollectScannedItems.includes(value) : false;
		},
		getPostal(postalId: number) {
			return self.postals.find(({ id }) => id === postalId);
		},
		get isCollectParcel(): boolean {
			const root = getRoot<typeof RootStore>(self);
			return self.postals.some(
				({ type, store_place_item_full_name: name, number }) =>
					type === 1 && root.operator?.isRouting && !!name && !this.entryPostalCollectItems(number)
			);
		},
		getOrder(orderId: number) {
			return self.orders.find(({ id }) => id === orderId);
		},
		get inventoryPostals() {
			return self.postals.filter(
				({ id, additional_services: { is_inventory } }) => is_inventory && !self.confirmedInventory.has(String(id))
			);
		},
		get confirmEnabled() {
			if (this.isCollectParcel) return false;
			if (!this.isReturnPostal && !this.hasPayment) return false;
			if (this.hasOrders)
				return this.isPaid && this.hasOrdersItems && !this.needAdultConfirmed && !this.hasNotCollected;
			if (this.isSumByCardExceeded || this.hasRequiredConfirm) return false;
			if (this.hasRequistions) return !(this.hasRequistionsProcess || !this.hasPayment);
			return this.hasPostalsConfirm || this.notHasPostalFinalSum;
		},
		get isPaid() {
			return this.paidOnline || this.change >= 0;
		},
		get notHasPostalFinalSum() {
			return !!(self.payment?.postal.final_sum && self.payment.postal.final_sum < 0);
		},
		get confirmText() {
			if (this.isRepeat) return 'Повторить';

			if (this.hasOrders) {
				return this.paidOnline ? 'Подтвердить выдачу заказа Emall' : 'Подтвердить оплату заказа Emall';
			}

			return this.notHasPostalFinalSum
				? 'Подтвердить выдачу средств'
				: this.hasPostalsConfirm
				? 'Подтвердить выдачу ПО'
				: 'Подтвердить оплату';
		},
		get hasOrders() {
			return self.orders.length > 0;
		},
		get hasOrdersItems() {
			const root = getRoot<typeof RootStore>(self);
			const emptyOrders: number[] = [];
			const orders = self.orders.filter(
				({ items, market_id: id }) =>
					items.some(({ received_quantity }) => received_quantity !== 0) || (emptyOrders.push(id) && false)
			);

			!orders.length &&
				root.setMessage({
					title: '',
					description: `Отсутствуют товары в заказе ${emptyOrders.join(
						', '
					)}. Для уточнения информации обратитесь в Службу поддержки сети ОПС PostHelp@evropochta.by`,
				});

			return orders.length > 0;
		},
		get hasOnlyPayments() {
			if (self.orders.length > 0) return false;
			return self.postals.filter(({ type }) => type === 2).length === self.postals.length;
		},
		get hasPostals() {
			return self.postals.some(({ type }) => type === 0 || type === 1);
		},
		get finalSum() {
			if (this.hasOrders) return self.payment?.order.final_sum || 0;
			return self.payment?.postal.final_sum || 0;
		},
		get paymentSum() {
			if (this.hasOrders) return self.payment?.order.payment_sum || 0;
			return self.payment?.postal.payment_sum || 0;
		},
		get isReturnPostal() {
			return (
				self.postals.filter((el) => el.market_status === 96 && el.type === 1).length === self.postals.length &&
				!!self.postals.length
			);
		},
		get isSumByCardExceeded() {
			return (self.paidInCard || 0) > (this.paymentSum || 0);
		},
		get needAdultConfirmed() {
			return this.hasAdultOrders ? !self.isAdultConfirmed : false;
		},
		get issueSum() {
			return self.payment?.postal.issue_sum || 0;
		},
		get certificateSum() {
			return self.payment?.order.certificate_sum || 0;
		},
		get paidOnline() {
			if (this.hasOrders) return self.payment?.order.final_sum === 0;
			return self.payment?.postal.final_sum === 0;
		},
		get change() {
			return round((self.paidInCard ?? 0) + (self.paidInCash ?? 0) - this.finalSum);
		},
		get hasRequiredConfirm() {
			return self.postals.some(
				({ isRequireCompletenessConfirm, type, market_status }) =>
					isRequireCompletenessConfirm && [1, 2].includes(type) && market_status !== 96
			);
		},
		get hasPostalsIssue() {
			return self.postals.some(({ type }) => [1, 2].includes(type));
		},
		get isRepeat() {
			return (
				self.postals.some(({ market_status_name: status }) => status === 'Ошибка') ||
				self.orders.some(({ status_name: status }) => status === 'Ошибка')
			);
		},
		get hasRequistions() {
			return self.postals.some(({ type }) => type === 0);
		},
		get hasPostalsConfirm() {
			return self.postals.some(({ type }) => type === 1) && (self.payment?.postal.final_sum === 0 || this.change >= 0);
		},
		get hasRequistionsProcess() {
			return self.postals.some(({ type, market_status }) => type === 0 && market_status === 0);
		},
		get hasNotCollected() {
			const root = getRoot<typeof RootStore>(self);
			if (root.operator?.isEmallPackingMode) return false;
			return self.orders.some(({ is_collected }) => !is_collected);
		},
		get hasPayment() {
			return this.notHasPostalFinalSum || this.change >= 0;
		},
		get newReceptions() {
			return self.postals.filter(({ type, market_status: status }) => type === 0 && status === 0);
		},
		getCountItemsByType(currentType: number) {
			if (currentType < 0) return self.orders.length + self.postals.length;
			if (currentType === 3) return self.orders.length;
			if (currentType >= 0) return self.postals.filter(({ type }) => type === currentType).length;
		},
		get lastFilter() {
			const filters = self.filterTypes.filter((item) => {
				if (item === -1) return false;

				const count = this.getCountItemsByType(item);
				return count ? count > 0 : false;
			});
			return filters.length === 1 ? filters[0] : null;
		},
		get hasPostalsNeedDocument() {
			return self.postals.some(({ need_document }) => need_document === 1);
		},
		getSearchNumberInVisit(str: string) {
			const numberPostal = self.postals.find(({ number }) => number.includes(str))?.number;
			const numberOrder = self.orders.find(({ market_id: id }) => `${id}`.includes(str))?.market_id;
			return numberPostal || (numberOrder && `${numberOrder}`);
		},
		get hasAdultOrders() {
			return self.orders.some((order) => order.is_adult === 1);
		},
	}));

export default Visit;

export type VisitModel = Instance<typeof Visit>;
